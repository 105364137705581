<script setup lang="ts"></script>

<template>
  <div class="overlay">
    <AppHeader />
    <div class="page flex items-center flex-col justify-center">
      <UDivider class="opacity-100 text-opacity-100 lg:hidden" />
      <slot />
    </div>
  </div>
</template>
